/*-------------------------------------------------------*/
/* Typography
/*-------------------------------------------------------*/

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $heading-font;
  margin-top: 0;
  margin-bottom: 10px;
  color: $heading-color;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: -0.025em;
}

h1 { font-size: 35px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; }
h5 { font-size: 18px; }
h6 { font-size: 15px; }

@include bp-sm-down {
  h1 { font-size: 26px; }
  h2 { font-size: 22px; }
  h3 { font-size: 18px; }
}

@media (max-width: 640px) {

  h1 { font-size: 24px; }
  h2 { font-size: 20px; }
  h3 { font-size: 19px; }
  h4 { font-size: 18px; }

}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  color: inherit;
}

.heading-row {
  margin-bottom: 30px;
}

.heading {
  position: relative;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.bottom-line {
  &:after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid $border-color;
    margin: 4px auto 0;
    z-index: 1;
    position: relative;
  }
}

p {
  font-size: 15px;
  color: $text-color;
  font-weight: normal;
  line-height: 26px;
  margin: 0 0 10px;
}

address {
  font-style: normal;
}

.lead {
  font-size: 18px;
  line-height: 32px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

@include bp-xl-down { .text-lg-center {text-align: center}; }
@include bp-lg-down { .text-md-center {text-align: center}; }
@include bp-md-down { .text-sm-center {text-align: center}; }
@include bp-sm-down { .text-xs-center {text-align: center}; }

blockquote {
  max-width: 80%;
  margin: auto;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;

  p {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 5px !important; 
    position: relative;
    font-family: $heading-font;
  }

  cite {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: #000;
  }
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid $border-color;
}
