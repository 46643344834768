// Colors
$main-color: #E12A21;
$alt-color: #f87219;
$text-color: #49545E;
$meta-color: #919BA3;
$heading-color: #3A444D;
$nav-color: #ffffff;
$bg-light: #E3E3E3;
$bg-dark: #002B4D;
$border-color: #e1e5e6;


// Fonts
$body-font: 'Roboto', sans-serif;
$heading-font: 'Poppins', sans-serif;


// Navbar
$nav-height: 50px;
$nav-short-height: 50px;