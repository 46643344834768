/*-------------------------------------------------------*/
/* Navigation
/*-------------------------------------------------------*/

.nav {
  min-height: $nav-short-height;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 120;
  position: relative;
  @include transition(height .3s ease-in-out);

  &__holder {
    background-color: $bg-dark;
  }

  @include bp-lg-up {
    height: $nav-height;

    .nav.sticky {
      height: $nav-height;
    }
  }

  &__menu,
  &__dropdown-menu {
    list-style: none;
  }

  &__menu {
    position: relative;
    @include display-inline-flex;
    align-items: center;

    & > li {
      position: relative;

      &:hover a:after {
        width: 100%;
      }    
    }

    & > li > a {
      font-family: $heading-font;
      color: $nav-color;
      font-size: 11px;
      font-weight: 600;
      padding: 0 16px;
      display: block;
      position: relative;
      text-transform: uppercase;
      line-height: $nav-short-height;
      letter-spacing: .06em;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: $main-color;
        @include transition($all);
      }

      &:hover {
        opacity: 0.95;
      }
    }
  }

  &__dropdown-menu,
  &__dropdown-submenu > .nav__dropdown-menu {

    & > li > a {
      font-family: $heading-font;
    }
  }


  /* Dropdowns (large screen) */
  @include bp-lg-up {

    &__menu > li {
      display: inline-block;
      text-align: center;
    }

    &__dropdown-menu,
    &__dropdown-submenu > .nav__dropdown-menu {
      position: absolute;
      padding: 15px 0;
      top: 100%;
      left: -6px;
      z-index: 1000;
      min-width: 230px;
      width: 100%;
      text-align: left;
      list-style: none;
      white-space: nowrap;
      background-color: #041726;
      box-shadow: 0 4px 20px rgba(0, 0, 0, .10);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      display: block;
      visibility: hidden;
      opacity: 0;
      @include transition (all .1s ease-in-out);


      & > li > a {
        color: lighten($meta-color, 10%);
        padding: 4px 23px;
        font-size: 13px;
        display: block;

        &:hover {
          color: #fff;
        }
      }
    }

    &__dropdown-menu.hide-dropdown {
      visibility: hidden !important;
      opacity: 0 !important;
    }

    &__dropdown-menu--right {
      right: 0;
    }

    &__dropdown:hover > .nav__dropdown-menu,
    &__dropdown-submenu:hover > .nav__dropdown-menu {
      opacity: 1;
      visibility: visible;
    }

    &__dropdown-submenu {
      position: relative;

      .nav__dropdown-menu {
        left: 100%;
        top: 0;
      }
    }
  }
}

.mobile body {
  cursor: pointer;
}


/* Logo
-------------------------------------------------------*/
.logo {
  line-height: 1;
}


/* Nav Flexbox
-------------------------------------------------------*/

header .flex-parent {
  align-items: center;
  height: $nav-short-height;
}

.nav__wrap {
  padding-left: 32px;
}

header .flex-child {
  flex: 1 0 0;
}

header .flex-child.flex-right {
  text-align: right;
}

.nav--align-right {
  margin-left: auto;
}

.nav--align-left {
  margin-right: auto;

  @include bp-md-up {
    margin-left: 60px;
  }  
}


/* Nav Right
-------------------------------------------------------*/
.nav__right {
  line-height: $nav-short-height;

  &-item {
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }
  }

  a:hover,
  a:focus {
    color: #fff;
  }
}


/* Nav Subscribe
-------------------------------------------------------*/
.nav__subscribe {
  font-family: $heading-font;
  color: #fff;
  font-size: 13px;
  line-height: 1;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 400px;
  }
}


/* Nav Socials
-------------------------------------------------------*/
.nav__socials {
  a {
    color: #fff;
  }

  a:hover {
    opacity: .9;
  }
}


/* Nav Search
-------------------------------------------------------*/

.nav__search {  

  &-box {
    width: 300px;
    position: absolute;
    right: 0;
    top: 100%;
    padding: 15px 20px;
    background-color: #fff;
    border-radius: 5px;
    display: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, .10);
  }

  &-form {
    position: relative;
  }

  &-input {
    margin-bottom: 0;
    display: block;
    line-height: 40px;
  }

  &-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 !important;
    width: 40px;
    border-radius: 0 5px 5px 0;
  }

  &-trigger {
    color: #fff;
    font-size: 15px;
    display: inline-block;
    width: 14px;
    line-height: 1;
  }

  &-trigger-icon {
    display: inline-block;
    width: 14px;
  }

  .ui-close {
    font-size: 20px;
    position: relative;
    top: 2px;
    left: 2px;
  }
}



/* Mobile Search */
.sidenav__search-mobile {

  &-form {
    position: relative;
  }

  &-input {
    height: 46px;
    border: none;
    background-color: transparent;
    margin-bottom: 0;
    font-family: $heading-font;
    font-size: 13px;
    color: $nav-color;
    border-radius: 0;
    border-bottom: 1px solid #182835;

    &:focus {
      background-color: transparent;
      border-color: #fff;
    }
  }

  &-submit {
    position: absolute;
    right: 0;
    top: 0;
    width: 46px;
    height: 46px;
    border: 0;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
    color: #9AA3AB;
    background-color: transparent;
  }
}

/* Change Color of Placeholders */
input.sidenav__search-mobile-input::-webkit-input-placeholder {
  color: #9AA3AB;
}

input.sidenav__search-mobile-input:-moz-placeholder {
  color: #9AA3AB;
  opacity: 1;
}

input.sidenav__search-mobile-input::-moz-placeholder {
  color: #9AA3AB;
  opacity: 1;
}

input.sidenav__search-mobile-input:-ms-input-placeholder {
  color: #9AA3AB;
}



/*-------------------------------------------------------*/
/* Nav Mobile Sidenav
/*-------------------------------------------------------*/

.sidenav {
  background-color: #041726;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  z-index: 121;
  overflow-y: auto;
  @include transition( transform .4s cubic-bezier(.16,.36,0,.98) );
  @include transform(translateX(-260px));

  &--is-open {
    @include transform(translateX(0));
  }
}

.main-container,
.nav__holder {
  @include transition( transform .4s cubic-bezier(.16,.36,0,.98) );

  &--is-pushed {
    @include transform(translateX(260px));
  }
}

.content-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 120;
  visibility: hidden;
  opacity: 0;
  @include transition( .3s cubic-bezier(.16,.36,0,.98) );
  background-color: rgba(#000,.8);

  &--is-visible {
    opacity: 1;
    visibility: visible;
  }
}


/* Nav Icon Toggle (mobile nav)
-------------------------------------------------------*/

.nav-icon-toggle {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  @include transition( 0.15s linear );
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  @include bp-lg-up {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &__box {
    width: 18px;
    height: 20px;
    position: relative;
    display: block;
  }

  &__inner {
    display: block;
    top: 50%;
    margin-top: -1px;
    @include transition( 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) );
  }

  &__inner,
  &__inner:before,
  &__inner:after {
    width: 18px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    @include transition(transform 0.30s ease);
  }

  &__inner:before,
  &__inner:after {
    content: "";
    display: block;
  }

  &__inner:before {
    top: -6px;
    @include transition ( top 0.2s 0.20s ease-in, opacity 0.1s ease-in );
  }

  &__inner:after {
    bottom: -6px;
    @include transition ( bottom 0.2s 0.20s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) );
  }

  &--is-open .nav-icon-toggle__inner {
    @include transition( .5s cubic-bezier(0.215, 0.61, 0.355, 1) );
    @include transform ( rotate(225deg) );
  }

  &--is-open .nav-icon-toggle__inner:before {
    top: 0;
    opacity: 0;
    @include transition ( top 0.1s ease-out, opacity 0.1s 0.12s ease-out );
  }

  &--is-open .nav-icon-toggle__inner:after {
    bottom: 0;
    @include transform( rotate(-90deg) );
    @include transition ( bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1) );
  }
}


/* Sidenav Menu
-------------------------------------------------------*/

.sidenav__menu {
  list-style: none;
  padding: 0;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    position: relative;
    border-bottom: 1px solid #182835;
    font-size: 14px;

    &:last-child {
      border-bottom: 0;
    }  
  } 
  
  &-dropdown {
    overflow: hidden;
    display: none;
    background-color: #fff;

    li {
      border-color: $border-color;
    }

    a,
    .sidenav__menu-toggle {
      color: $heading-color;

      &:hover {
        color: $heading-color;
      }
    }
  }
  
  &-toggle {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 12px;
    padding: 0;
    width: 46px;
    height: 46px;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  &--is-open > a {
    background-color: $bg-dark;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &--is-open > .sidenav__menu-toggle {
    @include transform(rotate(180deg));
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &-link {
    width: 100%;
    display: block;
    color: #fefefe;
    padding: 12px 16px;
    font-family: $heading-font;
    font-size: 15px;
    @include transition(background .3s ease);

    &:hover {
      color: #fff;
    }
  }   
}


/* Sidenav Socials
-------------------------------------------------------*/
.sidenav__socials {
  padding: 0 15px;
  margin-top: 20px;
}

/* Sticky Nav
-------------------------------------------------------*/
.nav--sticky {
  position: fixed;
  height: $nav-height;
  left: 0;
  right: 0;
  top: 0;

  &.sticky {
    z-index: 120;
    height: $nav-height;
  }
}

.nav.sticky {
  height: $nav-height;
}


/* Go to Top
-------------------------------------------------------*/

#back-to-top {
  display: block;
  z-index: 100;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 12px;
  position: fixed;
  bottom: -34px;
  right: 20px;
  line-height: 28px;
  background-color: #fff;
  border: 1px solid $border-color;
  box-shadow: 1px 1.732px 12px 0px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  @include transition ($all);
  text-decoration: none;

  i {
    @include transition ($all);
  }

  a {
    display: block;
    color: $text-color;
  }

  &.show {
    bottom: 20px;
  }

  &:hover {
    background-color: $main-color;
    border-color: transparent;
  }

  &:hover i {
    color: #fff;
  }
}


/* Top Bar
-------------------------------------------------------*/
.top-bar {
  height: 30px;
  border-bottom: 1px solid #353535;
  @include display-flex;
  align-items: center;

  a {
    color: #c1c1c1;
    font-size: 13px;

    &:hover {
      color: #fff;
    }
  }

  &__currency-language,
  &__right {
    @include flex(1 0 0);
  }

  &__promo {
    @include flex(2 0 0);
    margin-bottom: 0;
  }

  & > ul,
  &__currency,
  &__language {
    display: inline-block;
    position: relative;
  }

  &__language {
    margin-right: 10px;

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 13px;
      background-color: #353535;
      position: absolute;
      top: 5px;
      right: -7px;
    }
  }

  &__currency-dropdown,
  &__language-dropdown {
    background-color: lighten(#000, 5%);
    padding: 6px 16px;
    position: absolute;
    left: -16px;
    z-index: 150;
    visibility: hidden;
    opacity: 0;
    @include transition($all);
  }

  &__currency-dropdown ul li,
  &__language-dropdown ul li {
    line-height: 26px;
    border-top: 1px solid #353535;

    &:first-child {
      border-top: 0;
    }
  }

  &__currency:hover &__currency-dropdown,
  &__language:hover &__language-dropdown {
    visibility: visible;
    opacity: 1;
  }

  // Promo
  &__promo {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    color: #fff;
  }

  // Top bar right
  &__right {
    text-align: right;
  }

  &__item {
    display: inline-block;
    margin-left: 16px;

    &:nth-child(2) i {
      margin-right: 0;
    }

    & > i,
    & > a > i {
      margin-right: 5px;
      color: #fff;
    }
  }

  &__sign-in {
    text-transform: uppercase;
    letter-spacing: .06em;

    i {
      font-size: 12px;
    }
  }
}


/* Nav Cart
-------------------------------------------------------*/
.nav-cart {
  position: relative;

  &__dropdown {
    opacity: 0;
    visibility: hidden;
    min-width: 270px;
    max-width: 300px;
    position: absolute;
    top: 100%;
    left: auto;
    right: -15px;
    background-color: lighten(#000, 10%);
    padding: 20px;
    line-height: 1;
    text-align: left; 
    @include transition ($all);
    z-index: 201;

    &:before {
      content: '';
      position: absolute;
      width: 50px;
      height: 13px;
      display: block;
      top: -10px;
      right: 17px;
    }
  }

  &__items {
    position: relative;
  }

  &__item {
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #353535;
    position: relative;

    &:first-child {
      margin-top: 0;
    }
  }

  &__img {
    float: left;
    margin-right: 20px;
  }

  &__price {
    color: #c1c1c1;
    font-size: 13px;
    margin-top: 5px;
  }

  &__title {
    position: relative;
    overflow: hidden;
    margin-right: 30px;

    & > a {
      position: relative;
      color: #fff;
      font-size: 14px;
      vertical-align: top;
      line-height: 1.5;

      &:hover {
        color: #fff;
      }
    }
  }

  &__remove {
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #383838;

    a {
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      display: block;
      color: #c1c1c1;
    }

    i {
      font-size: 16px;
    }

    &:hover {
      background-color: $main-color;

      i {
        color: #fff;
      }
    }  
  }

  &__summary {
    margin-top: 10px;
    font-weight: 700;
    color: #fff;
    font-size: 14px;
  }

  &__total-price {
    float: right;
  }

  &__actions a {
    width: 100%;
    color: #fff;

    &:first-child {
      color: $heading-color;
    }

    &:hover {
      color: #fff;
    }
  }

  &:hover .nav-cart__dropdown {
    opacity: 1;
    visibility: visible;
  }
}


// Mobile Cart / Wishlist
.nav__mobile-cart,
.nav__mobile-wishlist {
  color: #fff;
  font-size: 18px;

  &:hover {
    color: #fff;
  }
}

.nav__mobile-cart-amount {
  font-size: 16px;
}

.nav__mobile-wishlist {
  margin-right: 18px;
}


/*-------------------------------------------------------*/
/* Nav Mobile Styles
/*-------------------------------------------------------*/

@include bp-lg-down {

  .nav {

    &__header {
      height: $nav-short-height;
    }

    &__wrap {
      text-align: left;
    }
  }

  // Logo
  .logo {
    line-height: 1;
    position: absolute;
    left: 50%;
    @include transform(translateX(-50%));
    text-align: center;
  }
}